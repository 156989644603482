<template>
    <v-layout
        mt-5
        justify-center>
        <v-flex
            xs12
            sm10
            md8
            lg5
            mt-3>
            <v-card
                light
                class="wrap-content gray-icons">
                <v-toolbar
                    dark
                    color="white"
                    class="first-line-toolbar">
                    <v-toolbar-title class="light-blue--text title-block title">
                        Upgrade Your Product
                    </v-toolbar-title>
                    <v-spacer />
                </v-toolbar>
                <v-card-text>
                    <v-container
                        fluid
                        grid-list-md>
                        <v-form
                            ref="form"
                            v-model="valid"
                            class="px-2">
                            <div id="feedInfo">
                                <v-layout
                                    row
                                    class="px-5 py-1">
                                    <v-flex xs12>
                                        <dealer-picker />
                                    </v-flex>
                                </v-layout>
                                <v-layout
                                    row
                                    class="px-5 py-1">
                                    <v-flex xs12>
                                        <v-autocomplete
                                            v-model="productType"
                                            item-text="name"
                                            :rules="dropdownRules"
                                            :items="products"
                                            return-object
                                            label="Product Type"
                                            light
                                            required />
                                    </v-flex>
                                </v-layout>
                                <v-layout
                                    v-if="!!currentDealer.id && dealerHasMarketplace() && !$userHasRole(['super_admin'])"
                                    row
                                    class="px-5 py-1">
                                    <v-flex xs12>
                                        <v-text-field
                                            v-model="adAccountId"
                                            label="Ad Account Id"
                                            :rules="dropdownRules"
                                            required />
                                    </v-flex>
                                </v-layout>
                                <v-layout
                                    v-if="$userHasRole(['super_admin'])"
                                    row
                                    class="px-5 py-1">
                                    <v-flex xs12>
                                        <v-text-field
                                            v-model="email"
                                            label="Email"
                                            :rules="dropdownRules"
                                            required />
                                    </v-flex>
                                </v-layout>
                                <v-layout
                                    row
                                    class="px-5 py-1">
                                    <v-flex xs12>
                                        <v-text-field
                                            v-model="radius"
                                            label="Radius"
                                            :rules="dropdownRules"
                                            required />
                                    </v-flex>
                                </v-layout>
                                <v-layout
                                    row
                                    class="px-5 py-1">
                                    <v-flex xs12>
                                        <v-text-field
                                            v-model="dailyBudget"
                                            label="Daily Budget"
                                            :rules="dropdownRules"
                                            required />
                                    </v-flex>
                                </v-layout>
                            </div>
                            <v-layout
                                class="py-5 ma-0 form-button"
                                row
                                justify-center
                                align-center>
                                <v-flex xs12>
                                    <div class="text-xs-center">
                                        <v-btn
                                            :disabled="!valid"
                                            flat
                                            large
                                            color="white"
                                            @click="submit()">
                                            Submit
                                        </v-btn>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-form>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
    import HTTP from '../../http';
    import DealerPicker from '../globals/DealerPicker';
    import { mapGetters } from 'vuex';

    export default {
        components: {
            'dealer-picker': DealerPicker
        },
        title: 'Upgrade Dealer',
        data() {
            return {
                valid: false,
                products: [],
                productType: null,
                dropdownRules: [v => !!v || 'This field is required'],
                existingAdAccount: false,
                adAccountId: null,
                email: null,
                radius: null,
                dailyBudget: null,
            };
        },
        computed: {
            ...mapGetters({
                currentDealer: 'getCurrentDealer'
            })
        },
        created() {
            this.$apiRepository = window.getApiRepositoryInstance();
        },
        mounted() {
            this.$apiRepository
                .getProducts()
                .then(response => {
                    this.products = response.data.data;
                    this.loadingProducts = false;
                });
        },
        methods: {
            submit() {
                (new HTTP)
                    .post('dealers/'+this.currentDealer.id+'/start-upgrade', {
                        product_id: this.productType.id,
                        facebook_ad_account_owner_email: this.$userHasRole(['super_admin']) ? this.email : null,
                        targeting_radius: this.radius,
                        daily_budget: this.dailyBudget,
                        ad_account_id: this.adAccountId,
                    })
                    .then(() => {
                        this.$flash('Success!', 'green');
                    });
            },
            dealerHasMarketplace() {
                console.log(this.currentDealer);
                return this.currentDealer.products.data.some(function(product) {
                    return product.name === 'Plumbing Marketplace Facebook';
                });
            },
        },
    };
</script>

