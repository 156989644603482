<template>
    <v-autocomplete
        ref="select"
        v-model="currentDealer"
        :items="dealers"
        :loading="loadingDealers ? 'primary' : false"
        :disabled="disabled"
        :menu-props="menuProps"
        item-text="name"
        item-value="id"
        class="dealerSelect dealer-select styled-select"
        append-icon="arrow_drop_down"
        color="grey lighten-2"
        label="ACCOUNT"
        placeholder="Select..."
        light
        return-object
        required
        @change="onChange"
        @keyup="search"
        @click="onFocus">
        <template
            slot="item"
            slot-scope="dealers">
            <v-list-tile-content>
                <v-list-tile-title>{{ dealers.item.name }}</v-list-tile-title>
                <v-list-tile-sub-title
                    v-if="showAgency && dealers.item.agency"
                    class="grey--text text--lighten-2">
                    {{ dealers.item.agency.name }}
                </v-list-tile-sub-title>
            </v-list-tile-content>
        </template>
        <template #no-data>
            <v-list-tile>
                <v-list-tile-title>
                    {{ (loadingDealers) ? 'Loading...' : 'No dealers found' }}
                </v-list-tile-title>
            </v-list-tile>
        </template>
    </v-autocomplete>
</template>

<script>
import { get } from 'lodash';

export default {
    props: {
        dealer: {
            validator(value) {
                return (typeof value === 'number' || typeof value === 'object');
            }
        },
        agencyId: {
            type: Number,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loadDefault: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            user: window.getUser(),
            show: false,
            typingTimer: null,
            loadingDealers: false,
            currentDealer: null,
            dealers: []
        };
    },
    computed: {
        showAgency() {
            if (window.userHasRole(['super_admin', 'admin'])) {
                return true;
            }
            return false;
        },
        menuProps() {
            if (this.showAgency) {
                return { contentClass: 'dealer-menu', maxHeight: 290 };
            }
            return {};
        }
    },
    watch: {
        async dealer(dealer) {

            // Try to get the ID from the dealer if it's an object, otherwise just return it
            const dealerId = get(dealer, 'id', null) || dealer;

            // If the ID hasn't changed there's nothing to do here
            if (this.currentDealer && dealerId === this.currentDealer.id) {
                return;
            }

            this.currentDealer = await this.getCurrentDealerByProps();
            this.$emit('dealer-load', this.currentDealer);
        },

        async agencyId(value) {

            if (!value) {
                return;
            }

            this.dealers = await this.getDealers();
        }

    },
    async created() {


        this.dealers = await this.getDealers();

        this.currentDealer = await this.getCurrentDealerByProps(this.loadDefault);

        // If there's a current dealer defaulted ensure it's in the list
        // Otherwise vuetify won't be able to select anything
        if (this.currentDealer) {
            this.dealers.push(this.currentDealer);
        }

        this.$emit('dealer-load', this.currentDealer);
    },
    methods: {

        async getDealers() {
            this.loadingDealers = true;
            const response = await this.$apiRepository.getDealerPickerInitialDealers(this.agencyId);
            this.loadingDealers = false;
            const dealers = response.data.data;
            return dealers.map(dealer => {
                dealer.needs_hydration = true;
                return dealer;
            });
        },

        async getCurrentDealerByProps(bypassNullCheck = false) {

            // Allow the form to be reset
            if (this.dealer === null && !bypassNullCheck) {
                return null;
            }
            // If a specific dealer ID has been passed as a number try to find it in the retrieved dealers
            if (typeof this.dealer !== 'undefined' &&
                typeof this.dealer === 'number') {

                let currentDealer = null;
                if (this.dealers.length) {
                    currentDealer = this.dealers.find(dealer => dealer.id === this.dealer);
                }
                if (!currentDealer) {
                    currentDealer = await this.$store.dispatch('getDealer', this.dealer);
                }

                this.$store.commit('updateCurrentDealer', currentDealer);
                return currentDealer;
            }
            // If a specific dealer ID has been passed as an object consider it the dealer
            if (this.dealer !== null &&
                typeof this.dealer !== 'undefined' &&
                typeof this.dealer === 'object') {

                this.$store.commit('updateCurrentDealer', this.dealer);
                return this.dealer;
            }

            return this.$store.state.dealer.currentDealer;
        },

        onFocus() {
            setTimeout(() => this.$refs.select.$refs.input.select(), 200);
        },

        async onChange(dealer) {
            // If the result came from a search we need to load the full dealer
            // this is necessary because most consumers use this data for display
            if (dealer.needs_hydration) {
                this.loadingDealers = true;
                dealer = await this.$store.dispatch('getDealer', dealer.id);
                this.loadingDealers = false;
            }

            this.$emit('selection', dealer);
            // Update the global scope so this can be used in other locations
            this.$store.commit('updateCurrentDealer', dealer);
        },

        search(e) {
            this.loadingDealers = true;
            this.dealers = [];
            if(this.typingTimer) clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {
                this.$apiRepository
                    .getDealerPickerSearchDealers(e.target.value, this.agencyId)
                    .then(response => {
                        const dealers = response.data.data;
                        // Add metadata to determine if we need to
                        // hydrate this result before sending it upstream
                        this.dealers = dealers.map(dealer => {
                            dealer.needs_hydration = true;
                            return dealer;
                        });
                        this.loadingDealers = false;
                    });
            }, 300);
        }
    }
};
</script>

<style lang="scss">
.dealer-menu {
    .v-list > div {
        padding: 10px 0;
    }
}

</style>

